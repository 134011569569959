<template>
  <ik-data-table
    :entity-name="$t('pay')"
    :model="model"
    icon="mdi-cash-plus"
  />
</template>

<script>
  import { IkDataTable } from 'metaflow-js'
  import Payment from '../../../model/common/Payment'

  export default {
    components: {
      IkDataTable,
    },
    data: function () {
      return ({
        model: Payment,
      })
    },
  }
</script>
